// Generated by Framer (c0fcd26)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["RjLvcje24", "odBBGfYgW", "LKg4EGr9J"];

const serializationHash = "framer-abfJp"

const variantClassNames = {LKg4EGr9J: "framer-v-1shiwth", odBBGfYgW: "framer-v-nu9uxy", RjLvcje24: "framer-v-1oum64q"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 3": "LKg4EGr9J", Desktop: "RjLvcje24", Mobile: "odBBGfYgW"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, E_waJ_ZaN: tap ?? props.E_waJ_ZaN, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "RjLvcje24"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, E_waJ_ZaN, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "RjLvcje24", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1gq4t4s = activeVariantCallback(async (...args) => {
if (E_waJ_ZaN) {
const res = await E_waJ_ZaN(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "odBBGfYgW") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-abfJp", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1oum64q", className)} data-framer-name={"Desktop"} data-highlight layoutDependency={layoutDependency} layoutId={"RjLvcje24"} onTap={onTap1gq4t4s} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", boxShadow: "0px 0.6021873017743928px 0.6021873017743928px -1.25px rgba(0, 0, 0, 0.17997), 0px 2.288533303243457px 2.288533303243457px -2.5px rgba(0, 0, 0, 0.15889), 0px 10px 10px -3.75px rgba(0, 0, 0, 0.0625)", ...style}} {...addPropertyOverrides({LKg4EGr9J: {"data-framer-name": "Variant 3"}, odBBGfYgW: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1vu4di9"} layoutDependency={layoutDependency} layoutId={"RRS2zbyy8"}><Image background={{alt: "", fit: "fit", intrinsicHeight: 1419, intrinsicWidth: 1421, pixelHeight: 1419, pixelWidth: 1421, sizes: "68px", src: "https://framerusercontent.com/images/f9k2PxaIaSIEWKm9v9u3ClNm6oU.png", srcSet: "https://framerusercontent.com/images/f9k2PxaIaSIEWKm9v9u3ClNm6oU.png?scale-down-to=512 512w, https://framerusercontent.com/images/f9k2PxaIaSIEWKm9v9u3ClNm6oU.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/f9k2PxaIaSIEWKm9v9u3ClNm6oU.png 1421w"}} className={"framer-ntkgzc"} data-framer-name={"Logo_picto_ITC_Data"} layoutDependency={layoutDependency} layoutId={"KWxyHrxZL"}/></motion.div>{isDisplayed() && (<motion.nav className={"framer-1wcd8db"} layoutDependency={layoutDependency} layoutId={"KGSWUlSJy"}/>)}</motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-abfJp [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-abfJp .framer-145v1p3 { display: block; }", ".framer-abfJp .framer-1oum64q { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; height: 70px; justify-content: space-between; mix-blend-mode: overlay; overflow: hidden; padding: 20px 20px 20px 20px; position: relative; width: 1200px; }", ".framer-abfJp .framer-1vu4di9 { flex: none; height: 50px; overflow: visible; position: relative; width: 199px; }", ".framer-abfJp .framer-ntkgzc { flex: none; height: 34px; left: 7px; overflow: visible; position: absolute; top: calc(50.00000000000002% - 34px / 2); width: 68px; }", ".framer-abfJp .framer-1wcd8db { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 30px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-abfJp .framer-1wcd8db { gap: 0px; } .framer-abfJp .framer-1wcd8db > * { margin: 0px; margin-left: calc(30px / 2); margin-right: calc(30px / 2); } .framer-abfJp .framer-1wcd8db > :first-child { margin-left: 0px; } .framer-abfJp .framer-1wcd8db > :last-child { margin-right: 0px; } }", ".framer-abfJp.framer-v-nu9uxy .framer-1oum64q { padding: 20px 20px 20px 10px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 70
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"odBBGfYgW":{"layout":["fixed","fixed"]},"LKg4EGr9J":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"E_waJ_ZaN":"tap"}
 * @framerImmutableVariables true
 */
const Framerqv1oaKmC9: React.ComponentType<Props> = withCSS(Component, css, "framer-abfJp") as typeof Component;
export default Framerqv1oaKmC9;

Framerqv1oaKmC9.displayName = "Nav Copy";

Framerqv1oaKmC9.defaultProps = {height: 70, width: 1200};

addPropertyControls(Framerqv1oaKmC9, {variant: {options: ["RjLvcje24", "odBBGfYgW", "LKg4EGr9J"], optionTitles: ["Desktop", "Mobile", "Variant 3"], title: "Variant", type: ControlType.Enum}, E_waJ_ZaN: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(Framerqv1oaKmC9, [])